import {
    BUY_TICKETS_EXPANSION_SET,
    CLEAR_CREATE_ORDER,
    CONFIRM_DIALOG_HIDE,
    CONFIRM_DIALOG_SHOW,
    CONFIRM_DIALOG_SUCCESS_SET,
    CONFIRM_SUPPORT_DIALOG_HIDE,
    CONFIRM_SUPPORT_DIALOG_SHOW,
    COUPON_CODE_DATA_FETCH_ERROR,
    COUPON_CODE_DATA_FETCH_IN_PROGRESS,
    COUPON_CODE_DATA_FETCH_SUCCESS,
    COUPON_CODE_REMOVE,
    COUPON_CODE_SET,
    CREATE_ORDER_ERROR,
    CREATE_ORDER_IN_PROGRESS,
    CREATE_ORDER_SUCCESS,
    EMAIL_ADDRESS_SET,
    FETCH_TICKET_TYPE_ERROR,
    FETCH_TICKET_TYPE_IN_PROGRESS,
    FETCH_TICKET_TYPE_SUCCESS,
    FULL_NAME_SET,
    MY_ORDER_FETCH_ERROR,
    MY_ORDER_FETCH_IN_PROGRESS,
    MY_ORDER_FETCH_SUCCESS,
    NO_OF_TICKETS_SET,
    OTP_VALUE_SET,
    OTP_VERIFY_SET,
    PAYMENT_METHOD_SET,
    STRIP_SECRET_FETCH_ERROR,
    STRIP_SECRET_FETCH_IN_PROGRESS,
    STRIP_SECRET_FETCH_SUCCESS,
    SUPPORT_EMAIL_SET,
    SUPPORT_ERROR,
    SUPPORT_IN_PROGRESS,
    SUPPORT_MESSAGE_SET,
    SUPPORT_NAME_SET,
    SUPPORT_POPOVER_HIDE,
    SUPPORT_POPOVER_SHOW,
    SUPPORT_PURPOSE_SET,
    SUPPORT_SUCCESS,
    TICKETS_DATA_SET,
    TIME_OUT_DIALOG_HIDE,
    TIME_OUT_DIALOG_SHOW,
} from '../constants/buyTicket';
import {
    CREATE_ORDER_URL,
    FETCH_TICKET_TYPE_URL,
    STRIP_SECRET_FETCH_URL,
    SUPPORT_URL,
    urlFetchCouponCodeData,
    urlFetchMyOrder,
} from '../constants/url';
import Axios from 'axios';

export const setTicketExpansion = (value) => {
    return {
        type: BUY_TICKETS_EXPANSION_SET,
        value,
    };
};

export const setCouponCode = (value) => {
    return {
        type: COUPON_CODE_SET,
        value,
    };
};

export const removeCouponCode = () => {
    return {
        type: COUPON_CODE_REMOVE,
    };
};

export const setNoOfTickets = (value) => {
    return {
        type: NO_OF_TICKETS_SET,
        value,
    };
};

export const setTicketsData = (value, valid) => {
    return {
        type: TICKETS_DATA_SET,
        value,
        valid,
    };
};

export const setFullName = (value) => {
    return {
        type: FULL_NAME_SET,
        value,
    };
};

export const setEmailAddress = (value, valid) => {
    return {
        type: EMAIL_ADDRESS_SET,
        value,
        valid,
    };
};

export const setOTP = (value) => {
    return {
        type: OTP_VALUE_SET,
        value,
    };
};

export const setPaymentMethod = (value) => {
    return {
        type: PAYMENT_METHOD_SET,
        value,
    };
};

export const setOTPVerify = () => {
    return {
        type: OTP_VERIFY_SET,
    };
};

export const showConfirmDialog = () => {
    return {
        type: CONFIRM_DIALOG_SHOW,
    };
};

export const hideConfirmDialog = () => {
    return {
        type: CONFIRM_DIALOG_HIDE,
    };
};

export const setConfirmDialogSuccess = (value) => {
    return {
        type: CONFIRM_DIALOG_SUCCESS_SET,
        value,
    };
};

export const showTimeOutDialog = () => {
    return {
        type: TIME_OUT_DIALOG_SHOW,
    };
};

export const hideTimeOutDialog = () => {
    return {
        type: TIME_OUT_DIALOG_HIDE,
    };
};

export const showSupportPopover = (event) => {
    return {
        type: SUPPORT_POPOVER_SHOW,
        anchorEl: event.currentTarget,
    };
};

export const hideSupportPopover = () => {
    return {
        type: SUPPORT_POPOVER_HIDE,
    };
};

export const showSupportConfirmDialog = () => {
    return {
        type: CONFIRM_SUPPORT_DIALOG_SHOW,
    };
};

export const hideSupportConfirmDialog = () => {
    return {
        type: CONFIRM_SUPPORT_DIALOG_HIDE,
    };
};

export const setSupportName = (value) => {
    return {
        type: SUPPORT_NAME_SET,
        value,
    };
};

export const setSupportEmail = (value, valid) => {
    return {
        type: SUPPORT_EMAIL_SET,
        value,
        valid,
    };
};

export const setSupportPurpose = (value) => {
    return {
        type: SUPPORT_PURPOSE_SET,
        value,
    };
};

export const setSupportMessage = (value, valid) => {
    return {
        type: SUPPORT_MESSAGE_SET,
        value,
        valid,
    };
};

const createOrderInProgress = () => {
    return {
        type: CREATE_ORDER_IN_PROGRESS,
    };
};

export const createOrderSuccess = (value) => {
    return {
        type: CREATE_ORDER_SUCCESS,
        value,
    };
};

const createOrderError = (message) => {
    return {
        type: CREATE_ORDER_ERROR,
        message,
        variant: 'error',
    };
};

export const createOrder = (data, cb) => (dispatch) => {
    dispatch(createOrderInProgress());

    Axios.post(CREATE_ORDER_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_nft_nashville_24'),
        },
    })
        .then((res) => {
            dispatch(createOrderSuccess(res.data && res.data.result));
            cb(res.data);
        })
        .catch((error) => {
            dispatch(createOrderError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

export const clearCreateOrder = () => {
    return {
        type: CLEAR_CREATE_ORDER,
    };
};

const fetchTicketTypeInProgress = () => {
    return {
        type: FETCH_TICKET_TYPE_IN_PROGRESS,
    };
};

const fetchTicketTypeSuccess = (value) => {
    return {
        type: FETCH_TICKET_TYPE_SUCCESS,
        value,
        variant: 'success',
    };
};

const fetchTicketTypeError = (message) => {
    return {
        type: FETCH_TICKET_TYPE_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchTicketType = () => (dispatch) => {
    dispatch(fetchTicketTypeInProgress());

    Axios.get(FETCH_TICKET_TYPE_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchTicketTypeSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchTicketTypeError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchOrderInProgress = () => {
    return {
        type: MY_ORDER_FETCH_IN_PROGRESS,
    };
};

export const fetchOrderSuccess = (value) => {
    return {
        type: MY_ORDER_FETCH_SUCCESS,
        value,
    };
};

const fetchOrderError = (message) => {
    return {
        type: MY_ORDER_FETCH_ERROR,
        message,
    };
};

export const fetchOrder = (id, cb) => (dispatch) => {
    dispatch(fetchOrderInProgress());

    const url = urlFetchMyOrder(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_nft_nashville_24'),
        },
    })
        .then((res) => {
            dispatch(fetchOrderSuccess(res.data && res.data.result));
            cb(res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(fetchOrderError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchCouponCodeDataInProgress = () => {
    return {
        type: COUPON_CODE_DATA_FETCH_IN_PROGRESS,
    };
};

export const fetchCouponCodeDataSuccess = (value) => {
    return {
        type: COUPON_CODE_DATA_FETCH_SUCCESS,
        value,
    };
};

const fetchCouponCodeDataError = (message) => {
    return {
        type: COUPON_CODE_DATA_FETCH_ERROR,
        message,
    };
};

export const fetchCouponCodeData = (id) => (dispatch) => {
    dispatch(fetchCouponCodeDataInProgress());

    const url = urlFetchCouponCodeData(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_nft_nashville_24'),
        },
    })
        .then((res) => {
            dispatch(fetchCouponCodeDataSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchCouponCodeDataError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchStripeSecretInProgress = () => {
    return {
        type: STRIP_SECRET_FETCH_IN_PROGRESS,
    };
};

export const fetchStripeSecretSuccess = (value) => {
    return {
        type: STRIP_SECRET_FETCH_SUCCESS,
        value,
    };
};

const fetchStripeSecretError = (message) => {
    return {
        type: STRIP_SECRET_FETCH_ERROR,
        message,
    };
};

export const fetchStripeSecret = (data, cb) => (dispatch) => {
    dispatch(fetchStripeSecretInProgress());

    Axios.post(STRIP_SECRET_FETCH_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_nft_nashville_24'),
        },
    })
        .then((res) => {
            dispatch(fetchStripeSecretSuccess(res.data && res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchStripeSecretError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const fetchSupportInProgress = () => {
    return {
        type: SUPPORT_IN_PROGRESS,
    };
};

export const fetchSupportSuccess = (value, message) => {
    return {
        type: SUPPORT_SUCCESS,
        value,
        message,
    };
};

const fetchSupportError = (message) => {
    return {
        type: SUPPORT_ERROR,
        message,
    };
};

export const fetchSupport = (data, cb) => (dispatch) => {
    dispatch(fetchSupportInProgress());

    Axios.post(SUPPORT_URL, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_nft_nashville_24'),
        },
    })
        .then((res) => {
            dispatch(fetchSupportSuccess(res.data && res.data.result, 'Thank you for contacting us. Our team will get back to you shortly.'));
            cb(res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(fetchSupportError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};
