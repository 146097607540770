import { HIDE_SIDE_BAR_SET, PROFILE_POPOVER_HIDE, PROFILE_POPOVER_SHOW, SHOW_SIDE_BAR_SET } from '../constants/navBar';

export const showSideBar = () => {
    return {
        type: SHOW_SIDE_BAR_SET,
    };
};

export const hideSideBar = () => {
    return {
        type: HIDE_SIDE_BAR_SET,
    };
};

export const showProfilePopover = (event) => {
    return {
        type: PROFILE_POPOVER_SHOW,
        anchorEL: event.currentTarget,
    };
};

export const hideProfilePopover = () => {
    return {
        type: PROFILE_POPOVER_HIDE,
    };
};
