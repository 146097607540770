import { combineReducers } from 'redux';
import {
    ACCESS_TOKEN_FETCH_ERROR,
    ACCESS_TOKEN_FETCH_IN_PROGRESS,
    ACCESS_TOKEN_FETCH_SUCCESS,
    EMAIL_SET,
    OTP_SET,
    SIGN_IN_DIALOG_HIDE,
    SIGN_IN_DIALOG_SHOW,
    SIGN_IN_ERROR,
    SIGN_IN_IN_PROGRESS,
    SIGN_IN_SUCCESS,
    SIGN_IN_VERIFY_ERROR,
    SIGN_IN_VERIFY_IN_PROGRESS,
    SIGN_IN_VERIFY_SUCCESS,
} from '../constants/signIn';

const email = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case EMAIL_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case SIGN_IN_DIALOG_HIDE:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const otp = (state = '', action) => {
    switch (action.type) {
    case OTP_SET:
        return action.value;
    case SIGN_IN_DIALOG_HIDE:
        return '';
    default:
        return state;
    }
};

const dialog = (state = false, action) => {
    switch (action.type) {
    case SIGN_IN_DIALOG_SHOW:
        return true;
    case SIGN_IN_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const signIn = (state = {
    inProgress: false,
    data: {},
    verify: {},
}, action) => {
    switch (action.type) {
    case SIGN_IN_IN_PROGRESS:
    case SIGN_IN_VERIFY_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SIGN_IN_SUCCESS:
        return {
            ...state,
            inProgress: false,
            data: action.value,
        };
    case SIGN_IN_ERROR:
    case SIGN_IN_VERIFY_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case SIGN_IN_VERIFY_SUCCESS:
        return {
            ...state,
            inProgress: false,
            verify: action.value,
        };
    default:
        return state;
    }
};

const token = (state = {
    inProgress: false,
    value: localStorage.getItem('acToken_nft_nashville_24'),
}, action) => {
    switch (action.type) {
    case ACCESS_TOKEN_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACCESS_TOKEN_FETCH_SUCCESS:
    case SIGN_IN_VERIFY_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ACCESS_TOKEN_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    email,
    otp,
    dialog,
    signIn,
    token,
});
